import React, { PropsWithChildren } from "react";
import { graphql } from "gatsby";
import { mergeStyleSets } from "@fluentui/react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Markdown from "../components/Markdown";

export interface PageGraphQL {
    data: {
        markdownRemark: {
            htmlAst: string;
        };
    };
}

const MainPage: React.FunctionComponent<PageGraphQL> = (props: PropsWithChildren<PageGraphQL>) => {
    const { data } = props;

    const mainStyle = mergeStyleSets({
        mainRoot: {
            padding: `0px 5px 0px 5px`,
        },
    });

    return (
        <Layout>
            <SEO title="Main" />
            <div className={mainStyle.mainRoot}>
                <Markdown htmlAst={data.markdownRemark.htmlAst} />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            htmlAst
        }
    }
`;

export default MainPage;
